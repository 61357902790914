import * as React from 'react';
import Layout from '../component/layout';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
    const { register, handleSubmit,reset,formState,formState: { errors, isSubmitSuccessful } } = useForm();
    const [dismissAlert, setDismissAlert] = React.useState(true);
    const [dismissSuccess, setDismissSuccess] = React.useState(true);
    const recaptchaRef = React.useRef();

    const onSubmit = async (formData) => {
        const token = await recaptchaRef.current.executeAsync();
        const formWithCaptcha = {...formData, 'g-recaptcha-response': token};
        emailjs.send('service_sgyouyou', 'template_youyoufamily', formWithCaptcha, 'user_FaCw8ObkOGpNHKK7lWqJX')
        .then((result) => {
            console.log(result.text);
            setDismissSuccess(false);
        }, (error) => {
            console.log(error.text);
            setDismissAlert(false);
        });
    };

    React.useEffect(() => {
        if (isSubmitSuccessful) {
          reset({ firstName: '',
                    lastName: '',
                    email: '',
                    message: '' });
        }
    }, [formState, reset, isSubmitSuccessful]);

    const handleClickSuccess = (e) => {
        e.preventDefault();
        setDismissSuccess(true);
    }

    const handleClickAlert = (e) => {
        e.preventDefault();
        setDismissAlert(true);
    }

    return (
        <Layout title={'Contact Us | YouYou'}
                keywords={['YouYou', 'Singapore', 'contact']}>
            <div className='grid grid-rows-auto grid-cols-1 md:grid-cols-2 gap-4 justify-center items-stretch m-3 md:m-10'>

                {/* row */}
                <div className='col-span-1 md:col-span-2 font-sylexiad text-center justify-center align-center relative'>
                    <div className='text-left inline-block'>
                        <p className='title text-2xl md:text-5xl'><strong>Find us on <a href='https://www.facebook.com/youyouwuv/' target='_blank' rel='noreferrer'>Facebook</a> ! :)<br /><hr />Or contact us using the form below <br /> ↓</strong></p>
                    </div>
                </div>

                {/* row */}
                <div className='col-span-1 md:col-span-2 font-sylexiad text-center justify-center align-center relative mx-3 md:mx-40'>
                    <div className='text-left inline-block'>
                        <p className='text text-left'>Mandatory fields are marked with *</p>
                    </div>
                </div>

                {/* row */}
                <div className='col-span-1 md:col-span-2 font-sylexiad mx-3 md:mx-40'>
                    <div className='text-left p-5 shadow-md rounded shadow-outline bg-white-500 border border-gray-100'>
                    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                        {dismissSuccess
                        ? <></>
                        : 
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative w-full" role="alert">
                            <strong className="font-bold">Successfully submitted!</strong>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                <svg onClick={handleClickSuccess} className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                            </span>
                            </div>
                        </div>
                        }
                        
                        {dismissAlert
                        ? <></>
                        : 
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full" role="alert">
                            <strong className="font-bold">Error submitting form</strong>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                <svg onClick={handleClickAlert} className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                            </span>
                            </div>
                        </div>
                        }
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                First Name *
                            </label>
                            <input className={!errors.firstName?'appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white'
                                                : 'appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white border-red-500'} id="grid-first-name" type="text"
                                                {...register("firstName", { required: true })}/>
                            {errors.firstName && <p className="text-red-500 text-xs italic">Please fill out this field.</p>}
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                Last Name
                            </label>
                            <input className='appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id="grid-last-name" type="text"
                                                {...register("lastName")}/>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-email">
                                    Email *
                                </label>
                                <input className={!errors.email?"appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                                    :"appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white border-red-500"} id="grid-subject" type="text"
                                                    {...register("email", { required: true,
                                                        validate : {
                                                            notEmpty: v => v !== '',
                                                            isPattern: v => /\S+@\S+\.\S+/.test(v)
                                                        }
                                                    })}/>
                                {errors.email && errors.email.type === 'required' && <p className="text-red-500 text-xs italic">Please fill out this field. </p>}
                                {errors.email && errors.email.type === 'isPattern' && <p className="text-red-500 text-xs italic">Please provide a valid email</p>}
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-subject" >
                                    Subject *
                                </label>
                                <input className={!errors.subject?"appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                                    :"appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white border-red-500"} id="grid-subject" type="text"
                                                    {...register("subject", { required: true })}/>
                                {errors.subject && <p className="text-red-500 text-xs italic">Please fill out this field.</p>}
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-message" >
                                    Message *
                                </label>
                                <textarea className={!errors.message?"w-full py-3 px-4 mb-3 bg-gray-200 text-gray-700 border rounded focus:outline-none focus:bg-white"
                                            :"w-full py-3 px-4 mb-3 bg-gray-200 text-gray-700 border rounded focus:outline-none focus:bg-white border-red-500"} rows="4" id="grid-message"
                                            {...register("message", { required: true })}></textarea>
                                {errors.message && <p className="text-red-500 text-xs italic">Please fill out this field.</p>}
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                        />
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6 text-center align-center justify-center">
                            <button className='general-button'>Submit</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Contact;